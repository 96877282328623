<template>
  <div class="home">
    <ul class="flexContent flex">
      <li class="leftData flex flex-column">
        <!-- 取电房间数 -->
        <div class="takeNumber bk_b">
          <dv-border-box-10>
            <roomsTop />
          </dv-border-box-10>
        </div>
        <!-- sos报警 -->
        <div class="sosAlarm bk_b">
          <!-- <dv-border-box-10>
            <grade class="listPd" />
          </dv-border-box-10> -->
          <dv-border-box-10>
            <div class="demo listPd">
              <checkInTime />
            </div>
          </dv-border-box-10>
        </div>

        <div class="sosAlarm bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <checkInType />
            </div>
          </dv-border-box-10>
          <!-- <dv-border-box-10>
            <div class="demo listPd">
            <placeOrder />
            </div>
          </dv-border-box-10> -->
        </div>
      </li>
      <li class="eachartsCont flex flex-column flex-1">
        <!-- <div id="myChart"></div> -->
        <div class="mapChart">
          <chinaMap ref="mapResize" showType="1" />
        </div>
        <div class="bar_charts">
          <barCharts showType="1" />
        </div>
      </li>
      <li class="rightData flex flex-column">
        <!-- 设备出租率 -->
        <div class="probability bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <eqAndpms />
            </div>
          </dv-border-box-10>
        </div>
        <!-- 出租率情况 -->
        <div class="probability bk_b">
          <dv-border-box-10>
            <enterDetails class="listPd" />
          </dv-border-box-10>
        </div>
        <div class="probability bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <feifang />
            </div>
          </dv-border-box-10>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import roomsTop from "../components/roomsTop2";
import checkInLine from "../components/checkInLine";
import placeOrder from "../components/placeOrder";
import chinaMap from "../components/chinaMap";
import barCharts from "../components/barCharts";
import enterDetails from "../components/enterDetails";
import eqAndpms from "../components/eqAndpms";
import checkInType from "../components/checkInType";
import grade from "../components/grade";
import checkInTime from "../components/checkInTime";
import feifang from "../components/feifang";

// import { borderBox1 } from "@jiaminghi/data-view";
export default {
  // name: "Home",
  components: {
    roomsTop,
    enterDetails,
    eqAndpms,
    checkInLine,
    placeOrder,
    chinaMap,
    barCharts,
    checkInType,
    grade,
    checkInTime,
    feifang,
  },

  data() {
    return {};
  },
  mounted() {
    // window.addEventListener("resize", () => {
    // });
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
@import "@/assets/mdKanban/kanbanPage.scss";
// .flexContent {
//   .rightData {
//     .probability {
//       height: 57vh !important;
//     }
//     .pmsTop {
//       height: 40vh !important;
//       margin-top: 1vh;
//     }
//   }
// }
</style>
